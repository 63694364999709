import React, { lazy, Suspense, useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Icon } from "@iconify/react";

import Spinner from "components/Spinner";
import Navbar from "components/Navbar";
import Footer from "components/Footer";

const Home = lazy(() => import("pages/Home"));
const AboutUs = lazy(() => import("pages/AboutUs"));
const OurTeam = lazy(() => import("pages/OurTeam"));
const OurScience = lazy(() => import("pages/OurScience"));
const Platform = lazy(() => import("pages/Platform"));
const ProductPortfolio = lazy(() => import("pages/ProductPortfolio"));
const Partners = lazy(() => import("pages/Partners"));
const Awards = lazy(() => import("pages/Awards"));
const ContactUs = lazy(() => import("pages/ContactUs"));

const App = () => {
   const [showTopBtn, setShowTopBtn] = useState(false);

   useEffect(() => {
      window.addEventListener("scroll", () => {
         if (window.scrollY > 400) {
            setShowTopBtn(true);
         } else {
            setShowTopBtn(false);
         }
      });

      goToTop();
   }, []);
   const goToTop = () => {
      window.scrollTo({
         top: 0,
         behavior: "smooth",
      });
   };
   return (
      <div className="font-inter">
         <Suspense fallback={<Spinner />}>
            <Router>
               <Navbar />
               <Routes>
                  <Route path="about-us" element={<AboutUs />} />
                  <Route path="our-team" element={<OurTeam />} />
                  <Route path="our-science" element={<OurScience />} />
                  <Route path="platform" element={<Platform />} />
                  <Route
                     path="product-portfolio"
                     element={<ProductPortfolio />}
                  />
                  <Route path="partners" element={<Partners />} />
                  <Route path="awards" element={<Awards />} />
                  <Route path="contactus" element={<ContactUs />} />
                  <Route path="/" element={<Home />} />
               </Routes>
               <Footer />
            </Router>
         </Suspense>
         {showTopBtn && (
            <button
               onClick={goToTop}
               className="fixed bottom-6 right-6 float-right z-50 bg-white rounded-full"
            >
               <Icon
                  icon="mdi:arrow-top-circle"
                  className="text-4xl text-primary"
               />
            </button>
         )}
      </div>
   );
};

export default App;
