import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { Icon } from "@iconify/react";

import websiteLogo from "assets/images/website-logo.png";

const Navbar = () => {
   const location = useLocation();
   const [isOpenNavbarItems, setIsOpenNavbarItems] = useState(false);

   return (
      <div className="fixed z-50 right-0 left-0 top-0 w-full bg-white">
         <div className="flex items-center justify-between w-full xl:max-w-6xl xl:space-x-16 m-auto py-3 px-3 xl:px-0">
            <Link to="/">
               <div>
                  <img
                     className="w-20 xl:w-24"
                     loading="lazy"
                     src={websiteLogo}
                     alt="website-logo"
                  />
               </div>
            </Link>

            <ul
               className={`${
                  isOpenNavbarItems ? "block" : "hidden"
               } absolute shadow-md xl:shadow-none xl:relative top-0 right-0 xl:top-auto xl:right-auto w-full xl:w-auto bg-white mt-16 pt-3 xl:pt-0 xl:mt-0 xl:space-y-0 xl:flex xl:items-center xl:space-x-4 text-tiny`}
            >
               <li className="border-b border-gray-300 xl:border-none py-1 xl:py-0">
                  <Link to="about-us" className="px-4 xl:px-0">
                     <button
                        onClick={() => setIsOpenNavbarItems(false)}
                        className={`${
                           location.pathname === "/about-us"
                              ? "xl:bg-gray-100 text-primary font-medium"
                              : "bg-white"
                        } px-2 py-2 w-full xl:w-auto text-left xl:text-center rounded-md whitespace-nowrap`}
                     >
                        About Us
                     </button>
                  </Link>
               </li>
               <li className="border-b border-gray-300 xl:border-none py-1 xl:py-0">
                  <Link to="our-team" className="px-4 xl:px-0">
                     <button
                        onClick={() => setIsOpenNavbarItems(false)}
                        className={`${
                           location.pathname === "/our-team"
                              ? "xl:bg-gray-100 xl:font-medium"
                              : "bg-white"
                        } px-2 py-2 w-full xl:w-auto text-left xl:text-center rounded-md whitespace-nowrap`}
                     >
                        Our Team
                     </button>
                  </Link>
               </li>
               <li className="border-b border-gray-300 xl:border-none py-1 xl:py-0">
                  <Link to="our-science" className="px-4 xl:px-0">
                     <button
                        onClick={() => setIsOpenNavbarItems(false)}
                        className={`${
                           location.pathname === "/our-science"
                              ? "bg-gray-100 font-medium"
                              : "bg-white"
                        } px-2 py-2 w-full xl:w-auto text-left xl:text-center rounded-md whitespace-nowrap`}
                     >
                        Our Science
                     </button>
                  </Link>
               </li>
               <li className="border-b border-gray-300 xl:border-none py-1 xl:py-0">
                  <Link to="platform" className="px-4 xl:px-0">
                     <button
                        onClick={() => setIsOpenNavbarItems(false)}
                        className={`${
                           location.pathname === "/platform"
                              ? "bg-gray-100 font-medium"
                              : "bg-white"
                        } px-2 py-2 w-full xl:w-auto text-left xl:text-center rounded-md whitespace-nowrap`}
                     >
                        Platform
                     </button>
                  </Link>
               </li>
               <li className="border-b border-gray-300 xl:border-none py-1 xl:py-0">
                  <Link to="product-portfolio" className="px-4 xl:px-0">
                     <button
                        onClick={() => setIsOpenNavbarItems(false)}
                        className={`${
                           location.pathname === "/product-portfolio"
                              ? "bg-gray-100 font-medium"
                              : "bg-white"
                        } px-2 py-2 w-full xl:w-auto text-left xl:text-center rounded-md whitespace-nowrap`}
                     >
                        Product Portfolio
                     </button>
                  </Link>
               </li>

               <li className="border-b border-gray-300 xl:border-none py-1 xl:py-0">
                  <Link to="partners" className="px-4 xl:px-0">
                     <button
                        onClick={() => setIsOpenNavbarItems(false)}
                        className={`${
                           location.pathname === "/partners"
                              ? "bg-gray-100 font-medium"
                              : "bg-white"
                        } px-2 py-2 w-full xl:w-auto text-left xl:text-center rounded-md whitespace-nowrap`}
                     >
                        Partners
                     </button>
                  </Link>
               </li>
               <li className="border-b border-gray-300 xl:border-none py-1 xl:py-0">
                  <Link to="awards" className="px-4 xl:px-0">
                     <button
                        onClick={() => setIsOpenNavbarItems(false)}
                        className={`${
                           location.pathname === "/awards"
                              ? "bg-gray-100 font-medium"
                              : "bg-white"
                        } px-2 py-2 w-full xl:w-auto text-left xl:text-center rounded-md whitespace-nowrap`}
                     >
                        Awards
                     </button>
                  </Link>
               </li>
               <li className="border-b border-gray-300 xl:border-none py-1 xl:py-0">
                  <Link to="contactus" className="px-4 xl:px-0">
                     <button
                        onClick={() => setIsOpenNavbarItems(false)}
                        className={`${
                           location.pathname === "/contactus"
                              ? "bg-gray-100 font-medium"
                              : "bg-white"
                        } px-2 py-2 w-full xl:w-auto text-left xl:text-center rounded-md whitespace-nowrap`}
                     >
                        Contact Us
                     </button>
                  </Link>
               </li>
               {/* <li className="relative">
               <button className="flex items-center space-x-1 hover:text-secondary">
                  <span className="cursor-pointer">Media</span>
                  <Icon
                     icon="material-symbols:keyboard-arrow-down-rounded"
                     className="text-xl"
                  />
               </button>

               <div
                  ref={dropDownRef}
                  className="absolute z-50 w-44 bg-white rounded divide-y divide-gray-100 shadow-md"
               >
                  <ul
                     className="py-1 text-sm text-black"
                     aria-labelledby="dropdownDefault"
                  >
                     <li className="border-b border-gray-300 xl:border-none py-1 xl:py-0">
                        <a
                           href="#"
                           className="block py-2 px-4 hover:text-secondary"
                        >
                           News
                        </a>
                     </li>
                     <li className="border-b border-gray-300">
                        <a
                           href="#"
                           className="block py-2 px-4 hover:text-secondary"
                        >
                           Awards
                        </a>
                     </li>
                     <li>
                        <a
                           href="#"
                           className="block py-2 px-4 hover:text-secondary"
                        >
                           Grants
                        </a>
                     </li>
                  </ul>
               </div>
            </li> */}
            </ul>
            <div className="flex items-center space-x-3">
               <button
                  onClick={() => setIsOpenNavbarItems(!isOpenNavbarItems)}
                  className="block xl:hidden"
               >
                  {isOpenNavbarItems ? (
                     <Icon
                        icon="radix-icons:cross-circled"
                        className="text-2xl"
                     />
                  ) : (
                     <Icon icon="ic:round-menu" className="text-2xl" />
                  )}
               </button>
               <a
                  target="_blank"
                  className="py-1 px-1 bg-secondary rounded-full"
                  href="https://www.linkedin.com/company/aodh-lifesciences-private-limited/"
               >
                  <svg
                     xmlns="http://www.w3.org/2000/svg"
                     viewBox="0 0 30 30"
                     className="w-5 h-5 text-white fill-current object-fill"
                  >
                     {" "}
                     <path d="M9,25H4V10h5V25z M6.501,8C5.118,8,4,6.879,4,5.499S5.12,3,6.501,3C7.879,3,9,4.121,9,5.499C9,6.879,7.879,8,6.501,8z M27,25h-4.807v-7.3c0-1.741-0.033-3.98-2.499-3.98c-2.503,0-2.888,1.896-2.888,3.854V25H12V9.989h4.614v2.051h0.065 c0.642-1.18,2.211-2.424,4.551-2.424c4.87,0,5.77,3.109,5.77,7.151C27,16.767,27,25,27,25z" />
                  </svg>
               </a>
               <a
                  target="_blank"
                  className="py-1 px-1 bg-secondary rounded-full"
                  href=""
               >
                  <svg
                     xmlns="http://www.w3.org/2000/svg"
                     viewBox="0 0 30 30"
                     className="w-5 h-5 text-white fill-current object-fill"
                  >
                     {" "}
                     <path d="M28,6.937c-0.957,0.425-1.985,0.711-3.064,0.84c1.102-0.66,1.947-1.705,2.345-2.951c-1.03,0.611-2.172,1.055-3.388,1.295 c-0.973-1.037-2.359-1.685-3.893-1.685c-2.946,0-5.334,2.389-5.334,5.334c0,0.418,0.048,0.826,0.138,1.215 c-4.433-0.222-8.363-2.346-10.995-5.574C3.351,6.199,3.088,7.115,3.088,8.094c0,1.85,0.941,3.483,2.372,4.439 c-0.874-0.028-1.697-0.268-2.416-0.667c0,0.023,0,0.044,0,0.067c0,2.585,1.838,4.741,4.279,5.23 c-0.447,0.122-0.919,0.187-1.406,0.187c-0.343,0-0.678-0.034-1.003-0.095c0.679,2.119,2.649,3.662,4.983,3.705 c-1.825,1.431-4.125,2.284-6.625,2.284c-0.43,0-0.855-0.025-1.273-0.075c2.361,1.513,5.164,2.396,8.177,2.396 c9.812,0,15.176-8.128,15.176-15.177c0-0.231-0.005-0.461-0.015-0.69C26.38,8.945,27.285,8.006,28,6.937z" />
                  </svg>
               </a>
            </div>
         </div>
      </div>
   );
};

export default Navbar;
