import React from "react";
import { Link } from "react-router-dom";
import { Icon } from "@iconify/react";

import aodhLogoImg from "assets/images/website-logo.png";

const Footer = () => {
   return (
      <div>
         <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
            <path
               fill="#aaf0d1"
               fillOpacity="1"
               d="M0,256L80,261.3C160,267,320,277,480,266.7C640,256,800,224,960,213.3C1120,203,1280,213,1360,218.7L1440,224L1440,320L1360,320C1280,320,1120,320,960,320C800,320,640,320,480,320C320,320,160,320,80,320L0,320Z"
            ></path>
         </svg>

         <div
            style={{ backgroundColor: "#aaf0d1" }}
            className="footer py-6 px-4 xl:px-0"
         >
            <div className="xl:grid xl:grid-cols-3 xl:gap-4 space-y-6 xl:space-y-0 justify-between items-start max-w-6xl mx-auto text-tiny">
               <div>
                  <h6 className="font-semiBold">Our Contact</h6>
                  <div className="mt-1 space-y-1">
                     <section>Aodh Lifesciences Pvt Ltd</section>
                     <section> II Floor, 12-5-32/8,</section>
                     <section>Next to Abbott India, Vijayapuri,</section>
                     <section>Tarnaka, Secunderabad, Telangana-500017.</section>
                     <section className="flex items-center space-x-1 leading-6">
                        <span>
                           <Icon icon="material-symbols:call" />
                        </span>
                        <p>+91-990-837-3343</p>
                     </section>
                     <section className="flex items-center space-x-1 leading-6">
                        <span>
                           <Icon icon="ic:baseline-email" className="text-lg" />
                        </span>
                        <p>ceo@aodhlifesciences.com</p>
                     </section>
                  </div>
               </div>
               <div>
                  <h6 className="font-semiBold">Quick Links</h6>
                  <div className="mt-1">
                     <ul className="grid grid-cols-2 items-center space-y-1">
                        <Link to="about-us">
                           <li>About us</li>
                        </Link>
                        <Link to="our-team">
                           <li>Our Team</li>
                        </Link>
                        <Link to="our-science">
                           <li>Our Science</li>
                        </Link>
                        <Link to="product-portfolio">
                           <li>Portfolio Product</li>
                        </Link>
                        <Link to="partners">
                           <li>Partners</li>
                        </Link>
                        <Link to="awards">
                           <li>Awards</li>
                        </Link>
                        <Link to="contactus">
                           <li>Contact us</li>
                        </Link>
                     </ul>
                  </div>
               </div>
               <div className="col-12  col-sm-5 col-md-5 col-lg-4">
                  <h6 className="font-semiBold">About Us</h6>
                  <div className="mt-1">
                     <p className="leading-6">
                        Aodh Lifesciences is a biopharmaceutical company based
                        in Hyderabad, Telangana with a innovative patent
                        portfolio, to develop an innovative platform of oxygen
                        delivering products to treat life-threatening diseases
                        where hypoxia plays a role.
                     </p>
                  </div>
               </div>
            </div>

            <div className="flex flex-col items-center justify-center space-y-3 text-tiny mt-10">
               <img src={aodhLogoImg} alt="aodh-logo-image" className="w-32" />
               <p>Aodh Lifesciences | All Rights Reserved</p>
            </div>
         </div>
      </div>
   );
};

export default Footer;
